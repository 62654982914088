import React, { useEffect, useState } from "react";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button } from "react-bootstrap";
import { getLocationById } from "../../utils/location.utils";
import { loadStripe } from '@stripe/stripe-js';
import Rating from '@mui/material/Rating';

const WaitStep = () => {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { idValetCar } = useParams();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ showPaymentSuccess, setShowPaymentSuccess ] = useState(true);
  const [ location, setLocation ] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : undefined)
  const [ valetCar, setValetCar ] = useState();

  const [rating, setRating] = useState(0)
  
  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}?accion=get_valet_car&id_valet_car=${idValetCar}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setValetCar(json);
      getLocationById(json.id_customer).then((location) => {
        setLocation(location);

        localStorage.removeItem('location');
        localStorage.removeItem('customer_id_valet_car');
        localStorage.removeItem('customer_price');
        localStorage.removeItem('customer_client_secret');
      }).catch((err) => {
        enqueueSnackbar("An error ocurred. Please try again later.", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
        history.push("/initial");
      }).finally(() => {
        setIsLoading(false);
      });
    });

    setTimeout(() => {
      setShowPaymentSuccess(false);
    }, 5000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}?accion=get_valet_car&id_valet_car=${idValetCar}`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        setValetCar(json);
      });
    }, 10000)
  }, [location])

  if (!idValetCar) {
    history.push("/payment");
    return;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-[100vh]">
        <i className="text-primary-6000 las la-spinner la-spin text-2xl"></i>
      </div>
    )
  }

  return (
    <div className="body_wrapper md:h-[100vh] overflow-y-hidden">
      {(valetCar?.status_status == "delivered" || valetCar?.status_status == "closed") ?
        <></>
        :
        <MainMenuApp NoText={true} Logo="1.svg" />
      }
      <section className="hero-area hero-ripples ripples relative">
        <div className="relative z-10 flex flex-col justify-between h-full">

          <img className="md:w-1/2 w-full object-cover h-[40vh] float-left" src={`${process.env.REACT_APP_IMAGES_ENDPOINT}${location?.imagen_bg}`} />
          
          <div className="md:w-1/2 fixed bottom-0 left-0 w-full bg-white !pt-6 rounded-t-2xl md:rounded-none shadow-[0_0_20px_rgba(0,0,0,0.5)] h-[62vh] text-neutral-900">
            <div className="h-full flex flex-col items-center justify-center space-y-4 text-center">
              <div className="flex flex-col justify-between h-full w-full">
                <div className="space-y-2 overflow-y-auto !px-4 pb-6 text-center flex flex-col items-center h-full justify-center">
                  {showPaymentSuccess ? 
                    <>
                      <i className="text-green-600 las la-check-circle text-7xl"></i>
                      <p>Payment processed sucessfully!</p>
                    </>
                    :
                    <>
                      {valetCar?.status_status == "pending" && Number(valetCar?.wait_qty) > 0 && <>
                        <br />
                        <img src={require('../../assets/image/cars-waiting.svg').default} className="w-24" />
                        <br />
                        <p>There {valetCar?.wait_qty > 1 ? `are ${valetCar?.wait_qty} cars` : `is ${valetCar?.wait_qty} car`} waiting before yours.</p>
                        <p>Estimated waiting time: <b className="font-bold text-primary-6000">{valetCar?.wait_time} minutes</b></p>
                      </>}
                      {valetCar?.status_status == "pending" && Number(valetCar?.wait_qty) == 0 && <>
                        <br />
                        <img src={require('../../assets/image/car-washing.svg').default} className="w-36" />
                        <br />
                        <p>We are washing your car.</p>
                        <p><b className="font-bold text-primary-6000">It will be ready anytime!</b></p>
                      </>}
                      {(valetCar?.status_status == "delivered" || valetCar?.status_status == "closed") && <>
                        <img src={require('../../assets/image/logo/logo.png')} className="w-36" />
                        <br />
                        <p>Your car is ready!</p>
                        <p><b className="font-bold text-primary-6000">Thank you for choosing Let's Wash!</b></p>
                        <div className="flex flex-col !mt-8 space-y-2">
                          <small>Rate our service:</small>                      
                          <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(event, newRating) => {
                              setRating(newRating);
                            }}
                          />
                        </div>
                      </>}
                    </>
                  }
                </div>
                {(valetCar?.status_status == "delivered" || valetCar?.status_status == "closed") ?
                  <></>
                  :
                  <div className="py-2.5 space-y-2 border-t border-neutral-200 px-3">
                    <Button 
                      variant="outline-info" 
                      className="w-full !text-secondary-6000 !border-secondary-6000 !rounded-lg !py-2 !flex space-x-2.5 !items-center justify-center !text-sm" 
                      size="lg"
                      onClick={() => window.Tawk_API.maximize()}
                    >
                      <i className="las la-headset !text-2xl"></i> <span>Got any questions? Contact us!</span>
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
          <iframe
            className="hidden md:block w-1/2 fixed top-0 left-1/2 h-[100vh] border-l border-neutral-200 border-solid "
            frameborder="0"
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?q=${location?.name}&key=AIzaSyA-kvvjMY47cmv3utgu1mJAMzc2MzS7Zbw`}
            allowfullscreen />
        </div>
      </section>
    </div>
  );
}

export default WaitStep;
