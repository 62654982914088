import React, { Component } from "react";
import Slider from "react-slick";
import CenterTitle from "./CenterTitle";
import TestimonialsItem from "./TestimonialsItem";

class Testimonials extends Component {
  render() {
    function SampleNextArrow(props) {
      const { onClick } = props;
      return (
        <div className="prev_arrow arrow" onClick={onClick}>
          <i className="fa fa-angle-left"></i>
        </div>
      );
    }

    function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
        <div className="next_arrow arrow" onClick={onClick}>
          <i className="fa fa-angle-right"></i>
        </div>
      );
    }
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "100px",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    var { BgImage, LeftTitle, RightTitle } = this.props;
    return (
      <section className="user_review_area !py-24 md:!py-32" id="testimonials">
        <span className="test_bg">
          <img src={require("../../assets/image/" + BgImage)} alt="" />
        </span>
        <div className="container">
          <CenterTitle Title="What our clients say" />
          <div className="user_review_slider">
            <Slider {...settings} className="review_slider">
              <TestimonialsItem
                Image="user-1.jpg"
                Details="Thanks to Let's Wash I have my own business and I'm about to open a new location. The investment was really low, I just needed to work hard to deliver a good service."
                Name="Christian Campbell - Let’s Wash location owner"
              />
              <TestimonialsItem
                Image="user-2.jpg"
                Details="Thanks to Let's Wash we were able to increase income by 15% in the first half of the year and more than 50% in the second half. They are amazing business partners."
                Name="Walter Abadía - Sheraton Buenos Aires"
              />
              <TestimonialsItem
                Image="user-3.jpg"
                Details="I went out for dinner with my family and I saw a Let's Wash sign in the lobby. It took me only 40 seconds and I came home with a clean van."
                Name="Susan Jones - Let’s Wash user"
              />
              <TestimonialsItem
                Image="user-4.jpg"
                Details="I scanned the QR, ordered a car wash and came back from the supermarket with a shiny car. Simply amazing, I recommend it."
                Name="David Martinez - Let’s Wash user"
              />
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}
export default Testimonials;
