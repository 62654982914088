import React, { Component } from "react";

class TestimonialsItem extends Component {
  render() {
    var {
      Image,
      Details,
      Icon1,
      Icon2,
      Icon3,
      Icon4,
      Icon5,
      IconUrl1,
      IconUrl2,
      IconUrl3,
      IconUrl4,
      IconUrl5,
      Name
    } = this.props;
    return (
      <div className="item">
        <img
          className="img-circle rounded-full !w-32"
          src={require("../../assets/image/" + Image)}
          alt=""
        />
        <p className="!text-neutral-600 !text-base"><i>“{Details}”</i></p>
        {/* <ul>
          <li>
            <a href={IconUrl1}>
              <i className={Icon1}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl2}>
              <i className={Icon2}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl3}>
              <i className={Icon3}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl4}>
              <i className={Icon4}></i>
            </a>
          </li>
          <li>
            <a href={IconUrl5}>
              <i className={Icon5}></i>
            </a>
          </li>
        </ul> */}
        <h4 className="!text-secondary-6000 !normal-case !font-[500]">{Name}</h4>
      </div>
    );
  }
}
export default TestimonialsItem;
