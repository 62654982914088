import React, { Component } from "react";
import FeatureBoxItem from "./FeatureBoxItem";

class FeatureBox extends Component {
  render() {
    return (
      <section className="overview_area">
        <div className="container">
          <div className="overview_inner row">
            <FeatureBoxItem
              Icon="ti-layers"
              Title="Unlimited Dashboards"
              Details="Every product must make a statement and we’re ready to create it with holistic approcach to design."
            />
            <FeatureBoxItem
              Icon="ti-ruler-pencil"
              Title="Unlimited Dashboards"
              Details="Every product must make a statement and we’re ready to create it with holistic approcach to design."
            />
            <FeatureBoxItem
              Icon="ion-document"
              Title="Unlimited Dashboards"
              Details="Every product must make a statement and we’re ready to create it with holistic approcach to design."
            />
          </div>
        </div>
      </section>
    );
  }
}
export default FeatureBox;
