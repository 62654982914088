import React, { Component } from "react";
import MenuTwo from "../Menu/MenuTwo";
import HomeFiveBanner from "../Elements/HomeFiveBanner";
import FeatureBox from "../Elements/FeatureBox";
import SwipeOffers from "../Elements/SwipeOffers";
import Testimonials from "../Elements/Testimonials";
import Team from "../Elements/Team";
import Download from "../Elements/Download";
import HomeArticle from "../Elements/HomeArticle";
import FooterTwo from "../Elements/FooterTwo";
import FooterData from "../Elements/jsonData/Fjson.js";
import Popup from "../Elements/Popup";

class HomeFive extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <MenuTwo Logo="2.png" />
        <HomeFiveBanner
          TitlePart1="A Perfect Landing Page to"
          TitlePart2="Showcase"
          TitlePart3="Your App"
          Details="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.."
          MobileImage="s9-img.png"
        />
        <FeatureBox />
        <SwipeOffers
          Title1="Swipe"
          Title2="Offers A Wide Range"
          Title3="Of Major Functionalites"
        />
        <Testimonials
          BgImage="testi-bg-curve.png"
          LeftTitle="We've Heard Our"
          CenterTitle="Customers"
          RightTitle="Say"
        />
        <Team LeftTitle="Meet Our Expert" RightTitle="Team" />
        <Download
          Tag="Swipe"
          Title="Download this app Now"
          Details="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium autem dolorem eaque est eum fugit harum maiores minima nisi nobis, possimus, quas qui quo ratione sequi suscipit vel vero voluptas?"
          AppStore1="an-btn-1.png"
          AppStore2="an-btn-1.png"
          Url1="#"
          Url2="#"
          MobileImage="social-mobile.png"
          Image1="like-1.png"
          Image2="like-2.png"
          Image3="love-1.png"
          Image4="love-2.png"
          Image5="smile-1.png"
          Image6="smile-2.png"
          Image7="wow-1.png"
        />
        <HomeArticle
          LeftTitle="Blog Articles. Our"
          CenterTitle="Thoughts"
          RightTitle="Ideas & Insights"
        />
        <FooterTwo
          ThemeName="Datasaas"
          LeftText="Copyright"
          Profile="weThemez"
          RightText="Developed By"
          Author="Rocky Ahammed"
          FooterData={FooterData}
        />
        <Popup />
      </div>
    );
  }
}

export default HomeFive;
