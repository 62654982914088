import React, { Component } from "react";

class FooterTwo extends Component {
  render() {
    var {
      ThemeName,
      LeftText,
      Profile,
      RightText,
      Author,
      Aurl,
      Purl
    } = this.props;
    let FooterData = this.props.FooterData;
    return (
      <footer className="footer_area">
        <div className="footer_social_area">
          <h4>{ThemeName}</h4>
          <ul className="social_f">
            {FooterData.SocialIcon.map(item => {
              return (
                <li key={item.id}>
                  <a href={item.url}>
                    <i className={item.icon}></i>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer_copyright">
          <h4>
            {LeftText} © {new Date().getFullYear()} <a href={Purl}>{Profile}</a>{" "}
            {RightText} <a href={Aurl}>{Author}</a>
          </h4>
        </div>
      </footer>
    );
  }
}
export default FooterTwo;
