import React, {Component} from 'react';



class DashItem extends Component{
    render(){
        var {Icon, Title, Details, Url, Class, delay} = this.props;
        return(
            <div className={"media powerful-item py-4 md:py-0 " + Class} data-wow-delay={delay}>
                <div className="media-left">
                    <img src={Icon} className=" m-auto md:m-0 w-12 h-12 brightness-[200] contrast-200 saturate-200 grayscale" />
                </div>
                <div className="media-body">
                    <a href={Url}><h2>{Title}</h2></a>
                    <p>{Details}</p>
                </div>
            </div>
        )
    }
}
export default DashItem;