import React, {Component} from 'react';

class CenterTitle extends Component{
    render(){
        var {Title, Subtitle} = this.props;
        return(
            <div className="sec-title">
                <h2 className='!font-semibold !text-2xl md:!text-3xl'>{Title}</h2>
                <p className='!text-base !text-neutral-600 m-auto !max-w-lg px-4'>{Subtitle}</p>
            </div>
        )
    }
}
export default CenterTitle;