import React, { Component } from "react";
import HomeBlogLeft from "./HomeLeftBlog";
import HomeBlogList from "./HomeBlogList";

class HomeArticle extends Component {
  render() {
    var { LeftTitle, CenterTitle, RightTitle } = this.props;
    return (
      <section className="home_article_area p_100" id="news">
        <div className="container">
          <div className="main_title">
            <h2>
              {LeftTitle} <samp>{CenterTitle}</samp>
              <br /> {RightTitle}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <HomeBlogLeft
                Image="l-news-1.jpg"
                Date="26 Feb 2020"
                DateUrl="#"
                Title="From its medieval origins to the dig-ital era, learn everything."
                TitleUrl="#"
                Details="Fed quia consequuntur magni dolores eos qui ratione voluptatem sequi
            nesciunt. Neque porro quisquam est, qui dolorem ipsum quia. Neque
            porro quisquam est, qui dolorem ipsum quia."
                BtnText="read more"
                Url="#"
              />
            </div>
            <div className="col-lg-5">
              <div className="l_nesw_list">
                <HomeBlogList
                  Image="l-news-2.jpg"
                  Title="Lorem ipsum passage vary, with some citing."
                  TitleUrl="#"
                  Date="05 Jan 2019"
                  DateUrl="#"
                />
                <HomeBlogList
                  Image="l-news-3.jpg"
                  Title="Lorem ipsum passage vary, with some citing."
                  TitleUrl="#"
                  Date="05 Jan 2019"
                  DateUrl="#"
                />
                <HomeBlogList
                  Image="l-news-4.jpg"
                  Title="Lorem ipsum passage vary, with some citing."
                  TitleUrl="#"
                  Date="05 Jan 2019"
                  DateUrl="#"
                />
                <HomeBlogList
                  Image="l-news-5.jpg"
                  Title="Lorem ipsum passage vary, with some citing."
                  TitleUrl="#"
                  Date="05 Jan 2019"
                  DateUrl="#"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeArticle;
