import React, { Component } from "react";

class Download extends Component {
  render() {
    var {
      Tag,
      Title,
      Details,
      Url1,
      Url2,
      AppStore1,
      AppStore2,
      MobileImage,
      Image1,
      Image2,
      Image3,
      Image4,
      Image5,
      Image6,
      Image7
    } = this.props;
    return (
      <section className="download_app_area p_100" id="download">
        <div className="container">
          <div className="download_app_inner row">
            <div className="col-lg-6">
              <div className="download_text">
                <h5>{Tag}</h5>
                <h4>{Title}</h4>
                <p>{Details}</p>
                <a className="btn_apple_w" href={Url1}>
                  <img
                    src={require("../../assets/image/" + AppStore1)}
                    alt=""
                  />
                </a>
                <a className="btn_apple_d" href={Url2}>
                  <img
                    src={require("../../assets/image/" + AppStore2)}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="download_img">
                <img
                  src={require("../../assets/image/social-like/" + MobileImage)}
                  alt=""
                />
                <ul>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image1)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image2)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image3)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image4)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image5)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image6)}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={require("../../assets/image/social-like/" + Image7)}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Download;
