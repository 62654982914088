import * as React from "react";
import { useTranslation } from "react-i18next";
import {get} from "lodash";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";


const languageOptions = {
  en: {
    icon: "/images/flags/us.png",
    name: "English",
  },
  es: {
    icon: "/images/flags/es.png",
    name: "Español",
  },
};

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const language = get(get(i18n, 'language').split('-'), '[0]', '');
  const selectedLanguage = languageOptions[language];

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
    closeMenu();
  };

  return (
    <React.Fragment>
      <Tooltip title="Languages">
        <MuiIconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          className="w-[55px] h-[20px]"
        >
          <img
            src={get(selectedLanguage, 'icon', '/images/flags/en.png')}
            alt={get(selectedLanguage, 'name', 'English')}
            className="w-full h-[20px] rounded"
          />
        </MuiIconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            <img
                src={languageOptions[language]?.icon}
                alt={languageOptions[language]?.name}
                className="w-[30px] h-[20px] rounded"
            />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;