import React, { Component } from "react";

class Popup extends Component {
  render() {
    return (
      <div
        className="modal fade search_box"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                id="search-popup"
                className="white-popup mfp-with-anim search_box_inner"
              >
                <h4>Search For What you Need</h4>
                <p>Config Your Theme Search Form Subtitle ThemeOption</p>
                <form
                  action="https://wethemez.com/wordpress/swipe"
                  id="search_from"
                  method="get"
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for..."
                      name="s"
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-default submit_s_btn"
                        type="submit"
                      >
                        Search
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Popup;
