import React, {Component} from 'react';

class WorkItem extends Component{
    render(){
        var {Icon, Title, Details, Class, delay, bootstrapCol = 4, titleClass, iconClass, imgClass} = this.props;
        return(
            <div className={`col-md-${bootstrapCol} work-item group py-4 md:py-0 ` + Class} data-wow-delay={delay}>
                {/* <div className="icon">
                    <span className={Icon}></span>
                </div> */}
                <div className={`icon flex items-center justify-center group ${iconClass}`}>
                    <img src={Icon} className={`h-12 ${imgClass}`} />
                </div>
                <h2 className={`!font-[500] !text-lg ${titleClass}`}>{Title}</h2>
                {Details && <p className='!text-base !text-neutral-500'>{Details}</p>}
            </div>
        )
    }
}
export default WorkItem;