const FooterData = {
  SocialIcon: [
    {
      id: 1,
      url: "https://www.facebook.com/",
      icon: "fa fa-facebook"
    },
    {
      id: 2,
      url: "https://twitter.com/",
      icon: "fa fa-twitter"
    },
    {
      id: 3,
      url: "https://www.instagram.com/",
      icon: "fa fa-instagram"
    },
    {
      id: 4,
      url: "https://www.pinterest.com/",
      icon: "fa fa-pinterest"
    },
    {
      id: 5,
      url: "#",
      icon: "fa fa-google-plus"
    }
  ]
};
export default FooterData;
