import React, {Component} from 'react';
import { Badge } from 'react-bootstrap';
import CenterTitle from './CenterTitle';
import WorkItem from './WorkItem';
import { useTranslation } from "react-i18next";



const Partners = ({AddClass}) => {

    const { t } = useTranslation();

    return(
        <section id="works" className={"how-work-area !py-16 md:!py-24 " + AddClass}>
            <CenterTitle Title="Open your own Let’s Wash location" Subtitle="Start operating with a minimum investment. We provide everything you need to get you started!" />
            <div className='px-4 pt-8 md:pt-0 md:px-20'>
                <div className="flex flex-col-reverse md:grid grid-cols-2">
                    <div className='space-y-12 pt-16 md:p-20 md:pt-10 text-center'>
                        <div className='space-y-4'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">1</Badge> <span>{t("partner.item0.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("partner.item0.subtitle")}</p>
                        </div>
                        <div className='space-y-4'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">2</Badge> <span>{t("partner.item1.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("partner.item1.subtitle")}</p>
                        </div>
                        <div className='space-y-4'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">3</Badge> <span>{t("partner.item2.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("partner.item2.subtitle")}</p>
                        </div>
                    </div>
                    <div className='md:pr-10 text-center'>
                        <img className='w-full drop-shadow -mt-8' src={require("../../assets/image/car-washed.png")} />
                    </div>
                </div>
            </div>
            <div className="subcribe w-full !max-w-none mt-5 md:mt-3">
                <a href='#partner' className="text-center !w-full !relative block">
                    <button className="btn !rounded-full p-3 px-5 font-bold text-white !bg-secondary-400" type="submit">{t("partner.button")}</button>
                </a>
            </div>
        </section>
    )
}
export default Partners;