import React, {Component, useState} from 'react';
import CenterTitle from './CenterTitle';
import { useSnackbar } from 'notistack';

const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const [isSending, setIsSending] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsSending(true);
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 
            'form-name': "contact", 
            'name': name,
            'email': email,
            'phone': phone,
            'company': company,
            'message': message,
            })
        }).finally(() => {
            enqueueSnackbar("Thank you for contacting us! We tipically respond within 24hs.", { variant: "success" });
            setName("");
            setEmail("");
            setPhone("");
            setCompany("");
            setMessage("");
            setIsSending(false);
        });
    }


    const encode = (data) => {
        return Object.keys(data).map(
            (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
        ).join('&');
    };

    return(
        <section className="subscribe-area" id="partner">
            <div className="container">
                <CenterTitle Title="Let's Partner" Subtitle="A business where everyone wins. Run your own Let’s Wash location or participate as a parking administrator/owner." />
                <form className="mailchimp block" method="post" onSubmit={(e) => handleSubmit(e)}>
                    <div className="space-y-5 md:space-y-0 md:grid md:grid-cols-2 w-full gap-5 max-w-3xl m-auto">
                        <div>
                            <label>Name *</label>
                            <input type="text" onChange={(e) => setName(e.target.value)} value={name} name="name" required className="!w-full rounded-xl px-3 py-2.5 border border-solid border-neutral-200" placeholder="John Smith" />
                        </div>
                        <div>
                            <label>Email *</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" required className="!w-full rounded-xl px-3 py-2.5 border border-solid border-neutral-200" placeholder="my@email.com" />
                        </div>
                        <div>
                            <label>Phone</label>
                            <input type="phone" onChange={(e) => setPhone(e.target.value)} value={phone} name="phone" className="!w-full rounded-xl px-3 py-2.5 border border-solid border-neutral-200" placeholder="(123) 456-7890" />
                        </div>
                        <div>
                            <label>Company</label>
                            <input type="text" onChange={(e) => setCompany(e.target.value)} value={company} name="company" className="!w-full rounded-xl px-3 py-2.5 border border-solid border-neutral-200" placeholder="My Company" />
                        </div>
                        <div className='col-span-2'>
                            <label>Comments</label>
                            <textarea type="text" onChange={(e) => setMessage(e.target.value)} value={message} name="message" className="!w-full rounded-xl px-3 py-2.5 border border-solid border-neutral-200" placeholder="Hello Let's Wash!" />
                        </div>
                    </div>
                    <div className="subcribe w-full !max-w-3xl md:!mt-5">
                        <label className="text-right">
                            <button disabled={isSending} className="btn !rounded-full p-3 px-5 font-bold text-white !bg-secondary-400" type="submit">
                                {isSending ? `Sending...` : `Send`}
                            </button>
                        </label>
                    </div>
                </form>
            </div>
        </section>
    )
}
export default Contact;