import React, { Component, useEffect, useRef, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory, useParams } from "react-router-dom";
import WaterWave from "react-water-wave";
import { useSnackbar } from "notistack";
import { Alert, Badge, Button, FormCheck, FormControl } from "react-bootstrap";
import { isClosedHour, isClosedToday, isClosed, getLocation } from "../../utils/location.utils";
import { convertNumbThousand } from "../../utils/format.utils";
import { Checkbox } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";


const PaymentStep = () => {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingPayment, setIsLoadingPayment ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(true);

  const [ location, setLocation ] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : undefined)
  
  const stripePromise = loadStripe(location?.is_demo == 1 ? process.env.REACT_APP_STRIPE_API_KEY_DEMO : process.env.REACT_APP_STRIPE_API_KEY, { locale: "en" });

  const [ requestCreated, setRequestCreated ] = useState(false);

  const [ idValetCar, setIdValetCar ] = useState(localStorage.getItem(`customer_id_valet_car`) ? localStorage.getItem(`customer_id_valet_car`) : undefined)
  const [ idCarType, setIdCarType ] = useState(localStorage.getItem(`customer_id_car_type_${location?.code}`) ? localStorage.getItem(`customer_id_car_type_${location?.code}`) : location?.tipos_vehiculo[0]?.id_customer_car_type);
  const [ idWashType, setIdWashType ] = useState(localStorage.getItem(`customer_id_wash_type_${location?.code}`) ? localStorage.getItem(`customer_id_wash_type_${location?.code}`) : location?.tipos_lavado[0]?.id_customer_wash_type);
  const [ brand, setBrand ] = useState(localStorage.getItem('customer_brand') ? localStorage.getItem('customer_brand') : undefined);
  const [ model, setModel ] = useState(localStorage.getItem('customer_model') ? localStorage.getItem('customer_model') : undefined);
  const [ licensePlateNumber, setLicensePlateNumber ] = useState(localStorage.getItem('customer_license_plate_number') ? localStorage.getItem('customer_license_plate_number') : undefined);
  const [ reference, setReference ] = useState(localStorage.getItem(`customer_reference_${location?.code}`) ? localStorage.getItem(`customer_reference_${location?.code}`) : undefined);
  const [ price, setPrice ] = useState(localStorage.getItem('customer_price'));

  const [ stripeOptions, setStripeOptions ] = useState(localStorage.getItem(`customer_client_secret`) ? { clientSecret: localStorage.getItem(`customer_client_secret`) } : undefined);

  const formRef = useRef();

  useEffect(() => {
    if (!isLoading && !requestCreated && (!idValetCar || !stripeOptions)) {
      setIsLoading(true);
      setRequestCreated(true);

      var data = {
        accion: "get_payment",
        id_customer: location.id_customer,
        id_customer_car_type: idCarType ? idCarType : "",
        id_customer_wash_type: idWashType ? idWashType : "",
        id_customer_option: [],
        brand: brand ? brand : "",
        model: model ? model : "",
        license_plate_number: licensePlateNumber ? licensePlateNumber : "",
        reference: reference ? reference : ""
      };
      var out = [];
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          out.push(key + '=' + encodeURIComponent(data[key]));
        }
      }
      var params = out.join('&');

      fetch(`${process.env.REACT_APP_API_ENDPOINT}?${params}`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        setIdValetCar(json.idValetCar);
        setStripeOptions({ clientSecret: json.paymentIntent });
        localStorage.setItem('customer_client_secret', json.paymentIntent);
        localStorage.setItem('customer_id_valet_car', json.idValetCar);
        return json;
      }).finally(() => {
        setIsLoading(false);
      })
    }
  }, [])

  useEffect(() => {
    if (!location) {
      history.push("/initial");
      return;
    }
    getLocation(location?.code).then((location) => {
      setLocation(location);
      localStorage.setItem('location', JSON.stringify(location));
    }).catch((err) => {
      enqueueSnackbar("An error ocurred. Please try again later.", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
      history.push("/initial");
    });
  }, []);

  return (
    <div className="body_wrapper md:h-[100vh] overflow-y-hidden">
      <MainMenuApp NoText={true} Logo="1.svg" />
      <section className="hero-area hero-ripples ripples">
        <div className="relative z-10 flex flex-col justify-between h-full">

          <img className="md:w-1/2 w-full object-cover h-[40vh] float-left" src={`${process.env.REACT_APP_IMAGES_ENDPOINT}${location?.imagen_bg}`} />
          
          <div className="md:w-1/2 fixed bottom-0 left-0 w-full bg-white !pt-6 rounded-t-2xl md:rounded-none shadow-[0_0_20px_rgba(0,0,0,0.5)] h-[62vh] text-neutral-900">
            {(isLoading || !stripeOptions) ?
              <div className="flex items-center justify-center w-full h-full">
                <i className="text-primary-6000 las la-spinner la-spin text-2xl"></i>
              </div>
              :
              <div className="flex flex-col justify-between h-full">
                <div className="space-y-4 overflow-y-auto !px-4 pb-6">
                  <div>
                    <p className="text-base text-neutral-700">Last step... enter your card details or pay with Apple Pay, Google Pay or PayPal. Your total is <b className="font-bold">${convertNumbThousand(price)}</b></p>
                  </div>
                  <Elements stripe={stripePromise} options={stripeOptions}>
                    <CheckoutForm formRef={formRef} setIsDisabled={setIsDisabled} setIsLoadingPayment={setIsLoadingPayment} />
                  </Elements>
                </div>
                <div className="py-2.5 space-y-2 border-t border-neutral-200 px-3">
                  <div className="grid grid-cols-5 gap-3">
                    <Button 
                      variant="outline-info" 
                      className="col-span-1 !text-secondary-6000 !border-secondary-6000 !rounded-lg !py-2 !text-2xl" 
                      size="lg"
                      onClick={() => window.Tawk_API.maximize()}
                    >
                      <i className="las la-headset"></i>
                    </Button>
                    <Button 
                      className={`col-span-4 !text-sm !font-semibold uppercase !rounded-lg ${isClosed(location) ? `!bg-red-800 !border-red-800 !opacity-40` : `!bg-primary-6000`} ${isLoadingPayment ? `!opacity-80` : ``}`} 
                      size="lg"
                      disabled={isClosedToday(location) || isLoadingPayment}
                      onClick={() => formRef?.current.dispatchEvent(new Event("submit"))}
                    >
                      {isLoadingPayment ? 
                        <>
                          <i className="las la-spin la-spinner"></i> Processing...
                        </>
                        :
                        <>
                          {isClosedToday(location) ?
                            "Closed today" 
                            :
                            isClosedHour(location) ?
                              "Closed now"
                              :
                              <>Pay &nbsp;<span className="!font-light">|</span>&nbsp; ${convertNumbThousand(price)}</>
                          }
                        </>
                      }
                    </Button>
                  </div>
                </div>
              </div>
            }
          </div>

          <iframe
            className="hidden md:block w-1/2 fixed top-0 left-1/2 h-[100vh] border-l border-neutral-200 border-solid "
            frameborder="0"
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?q=${location?.name}&key=AIzaSyA-kvvjMY47cmv3utgu1mJAMzc2MzS7Zbw`}
            allowfullscreen />
        </div>
      </section>
    </div>
  );
}

export default PaymentStep;
