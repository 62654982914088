import React, {Component} from 'react';
import { Badge } from 'react-bootstrap';
import CenterTitle from './CenterTitle';
import WorkItem from './WorkItem';
import { useTranslation } from "react-i18next";



const Administrators = ({AddClass}) => {

    const { t } = useTranslation();
    return(
        <section id="works" className={"how-work-area !bg-white !m-0 !p-0 " + AddClass}>
            <div className=''>
                <div className="flex flex-col md:grid grid-cols-2">
                    <div>
                        <img className='object-cover md:h-full w-full md:w-auto' src={require("../../assets/image/background.jpg")} /> 
                    </div>
                    <div className='space-y-12 px-4 !py-10 md:!pl-20 md:!py-20 text-center'>
                        <div className="md:!text-left space-y-12 md:px-5">
                            <h2 className='!font-semibold !text-2xl md:!text-3xl md:!text-left text-primary-700'>{t("administrator.title")}</h2>
                            <p className='!text-base !text-neutral-600 md:!text-left'>{t("administrator.subtitle")}</p>
                        </div>
                        <div className='space-y-3 md:px-5'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">1</Badge> <span>{t("administrator.item0.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("administrator.item0.subtitle")}</p>
                        </div>
                        <div className='space-y-3 md:px-5'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">2</Badge> <span>{t("administrator.item1.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("administrator.item1.subtitle")}</p>
                        </div>
                        <div className='space-y-3 md:px-5'>
                            <h2 className='text-base text-neutral-800 flex items-center justify-center md:justify-start space-x-2'><Badge className="bg-secondary-400 text-white px-2">3</Badge> <span>{t("administrator.item2.title")}</span></h2>
                            <p className='text-base text-neutral-600 md:text-left'>{t("administrator.item2.subtitle")}</p>
                        </div>
                        <div className="subcribe w-full !max-w-none mt-5 md:mt-3">
                            <a href='#partner' className="text-center md:text-left !w-full !relative block">
                                <button className="btn !rounded-full p-3 px-5 font-bold text-white !bg-secondary-400" type="submit">{t("administrator.button")}</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Administrators;