import React, { Component, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory } from "react-router-dom";

const InitialStep = () => {

  const [ search, setSearch ] = useState();
  const history = useHistory();

  const handleOnClick = () => {
    if (search) {
      history.push(`/location/${search}`);
    }
  }

  return (
    <div className="body_wrapper h-[100vh]">
      <MainMenuApp Logo="1.svg" />
      <RippleBanner BtnText="Search" Url="" Image="home.jpg" setSearch={setSearch} OnClick={handleOnClick} />
    </div>
  );
}

export default InitialStep;
