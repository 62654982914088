import React, { Component } from "react";

class TeamSocail extends Component {
  render() {
    var { Url, Icon } = this.props;
    return (
      <li>
        <a href={Url}>
          <i className={Icon} aria-hidden="true"></i>
        </a>
      </li>
    );
  }
}
export default TeamSocail;
