import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import "../../index.css";
import NavbarLanguagesDropdown from "../NavbarLanguageDropdown";

const MainMenu = ({ Logo }) => {

  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  })

  return (
    <header
      className={scrolled ? "main_menu scrollled" : "main_menu"}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-light header-area md:!px-20">
        <a className="navbar-brand flex space-x-2 items-center" href=".#">
          <img className="h-14 md:h-[4rem] inline-block" src={require("../../assets/image/logo/" + Logo)} alt="" />
          <img className="h-4 md:h-[1.2rem] inline-block" src="/images/logo_text.svg" alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
        </button>
        <div className="collapse navbar-collapse !visible" id="navbarSupportedContent">
          <ul className="nav navbar-nav menu_nav ml-auto menu">
            <li>
              <a
                className="nav-link"
                activeClass="active"
                href="#"
                spy="true"
                smooth="true"
                offset={0}
                duration={500}
              >
                {t('navbar.home')}
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                activeClass="active"
                href="#works"
                spy="true"
                smooth="true"
                offset={0}
                duration={500}
              >
                {t('navbar.how_it_works')}
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                activeClass="active"
                href="#dashboard"
                spy="true"
                smooth="true"
                offset={0}
                duration={500}
              >
                {t('navbar.why_us')}
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                activeClass="active"
                href="#partner"
                spy="true"
                smooth="true"
                offset={0}
                duration={500}
              >
                {t('navbar.lets_partner')}
              </a>
            </li>
            <li>
              <span
                className="nav-link cursor-pointer"
                activeClass="active"
                spy="true"
                smooth="true"
                offset={0}
                duration={500}
              >
                <NavbarLanguagesDropdown />
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
export default MainMenu;
