import React, {Component} from 'react';
import AboutWD from './widgets/AboutWD';
import LinkWD from './widgets/LinkWD';
import WorkWD from './widgets/WorkWD';
import WidgetData from './widgets/WidgetData';
import LegalWD from './widgets/LegalWD';
import SubscribeWD from './widgets/SubscribeWD';
import ContactWD from './widgets/ContactWD';

class Footer extends Component{
    render(){
        return(
            <footer className="footer-area">
                <div className="footer-top !py-16">
                    <div className="container">
                        <div className="row">
                            <AboutWD Logo="logo_full.png" />
                            <ContactWD Title="Contact" WidgetData={WidgetData} Class="wow fadeInUp" delay="200ms" />
                            <SubscribeWD Title="Subscribe" WidgetData={WidgetData} Class="wow fadeInUp" delay="800ms" />
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-primary-6000">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className='text-center !text-white'> © {(new Date().getFullYear())} Let's Wash LLC, All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;