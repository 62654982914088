import React, { Component } from "react";

class FeatureBoxList extends Component {
  render() {
    return (
      <li>
        <a href={this.props.Url}>
          <i className="ti-check-box"></i>
          {this.props.Text}
        </a>
      </li>
    );
  }
}
export default FeatureBoxList;
