import React, { Component } from "react";

import Background from "../../assets/image/main-background.png";

var Image = {
  background: `url(${Background})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right"
};

class HomeFiveBanner extends Component {
  render() {
    var {
      TitlePart1,
      TitlePart2,
      TitlePart3,
      Details,
      Url1,
      Url2,
      MobileImage
    } = this.props;
    return (
      <section className="main_bg_area" style={Image} id="home">
        <ul className="list">
          <li data-parallax='{"x": 0, "y": 200}'>
            <img src={require("../../assets/image/round-1.png")} alt="" />
          </li>
          <li data-parallax='{"x": 0, "y": 300}'>
            <img src={require("../../assets/image/round-1.png")} alt="" />
          </li>
          <li data-parallax='{"x": 0, "y": -300}'>
            <img src={require("../../assets/image/round-1.png")} alt="" />
          </li>
          <li data-parallax='{"x": 0, "y": -200}'>
            <img src={require("../../assets/image/round-1.png")} alt="" />
          </li>
          <li data-parallax='{"x": 100, "y": 0}'>
            <img src={require("../../assets/image/round-2.png")} alt="" />
          </li>
        </ul>
        <div className="container">
          <div className="row main_text_inner">
            <div className="col-md-6">
              <div className="main_left">
                <h3>
                  {TitlePart1} <samp>{TitlePart2}</samp> {TitlePart3}
                </h3>
                <p>{Details}</p>
                <a className="btn_apple_d" href={Url1}>
                  <img
                    src={require("../../assets/image/an-btn-1.png")}
                    alt=""
                  />
                </a>
                <a className="btn_apple_d" href={Url2}>
                  <img
                    src={require("../../assets/image/btn-apple.png")}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_right">
                <img
                  className="img-fluid"
                  src={require("../../assets/image/" + MobileImage)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeFiveBanner;
