import React, { Component, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
 
const TawkTo = () => {
  
  const [newMessages, setNewMessages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleClick = () => {
    window.Tawk_API.maximize();
    setNewMessages(0);
  }

  // useEffect(() => {
  //   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //   s1.async=true;
  //   s1.src='https://embed.tawk.to/637f5e14b0d6371309d0e0aa/1gj6s6gaq';
  //   s1.charset='UTF-8';
  //   s1.setAttribute('crossorigin','*');
  //   s0.parentNode.insertBefore(s1,s0);
  // }, []);

  // useEffect(() => {
  //   window.Tawk_API = window.Tawk_API || {};
  //   window.Tawk_API.onLoad = function(){
  //     setIsLoaded(true);
  //   };
  // }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.title.includes("new message")) {
        if (!window.Tawk_API.isChatMaximized()) {
          setNewMessages(parseInt((document.title.split(" "))[0]));
        }
      }
    }, 400);
  }, [])

  return (
    <>
      <TawkMessengerReact 
        propertyId="637f5e14b0d6371309d0e0aa" 
        widgetId="1gj6s6gaq"
        onLoad={() => setIsLoaded(true)}
        onChatMessageVisitor={() => setNewMessages(newMessages + 1)}
      />
      <Button 
        variant="outline-info" 
        className="col-span-1 !text-secondary-6000 !border-secondary-6000 !rounded-lg !py-2 !text-2xl relative" 
        size="lg"
        onClick={handleClick}
      >
        {newMessages > 0 && (
          <Badge variant="danger" className="!absolute !-top-1.5 -right-1 !text-xs px-2 !rounded-full">{newMessages}</Badge>
        )}
        <i className="las la-headset"></i>
      </Button>
    </>
  )
}

export default TawkTo;