import React, { Component } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import VideoBanner from "../Elements/VideoBanner";

class HomeFour extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <MainMenu Logo="1.png" />
        <VideoBanner
          VideoName="startup.mp4"
          Details="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labo."
          BtnText="Get Started"
          Url="#"
        />
        <Works AddClass="how-work2" />
        <TopFeature />
        <Dashboard
          LeftTitle="Our Dashboard features"
          RightTitle="Our Dashboard features"
        />
        <DashboardTab />
        <CustomerSlider />
        <PriceList />
        <Subscribe />
        <Footer WidgetData={WidgetData} />
      </div>
    );
  }
}

export default HomeFour;
