import React, { Component } from "react";

class OfferRight extends Component {
  render() {
    var {
      MobileImg,
      LoveImg,
      ChatImg,
      InternetImg,
      Title,
      Details,
      BtnText,
      Url
    } = this.props;
    return (
      <div className="feature_inner feature_tow row">
        <div className="col-lg-7">
          <div className="feature_list">
            <img src={require("../../assets/image/" + MobileImg)} alt="" />
            <ul>
              <li>
                <img src={require("../../assets/image/" + LoveImg)} alt="" />
              </li>
              <li>
                <img src={require("../../assets/image/" + ChatImg)} alt="" />
              </li>
              <li>
                <img
                  src={require("../../assets/image/" + InternetImg)}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="feature_text">
            <div className="icon">
              <i className="fa fa-handshake-o"></i>
            </div>
            <h4>{Title}</h4>
            <p>{Details}</p>
            <a className="store_btn" href={Url}>
              {BtnText}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default OfferRight;
