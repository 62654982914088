import React, { Component } from "react";
import { Link } from "react-scroll";
import "../../index.css";

class MainMenuApp extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false
    };
  }

  render() {
    var { Logo, NoText = false } = this.props;
    return (
      <header
        className={this.state.scrolled ? "main_menu scrollled" : "main_menu"}
      >
        <nav className="navbar navbar-expand-lg navbar-light bg-light header-area md:!px-20 flex justify-center">
          <a className="navbar-brand flex space-x-2 items-center" href="/initial">
            <img className="h-14 md:h-[4rem] inline-block" src={require("../../assets/image/logo/" + Logo)} alt="" />
            {!NoText && <img className="h-4 md:h-[1.2rem] inline-block" src="/images/logo_text.svg" alt="" />}
          </a>
        </nav>
      </header>
    );
  }
}
export default MainMenuApp;
