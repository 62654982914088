import React, {Component} from 'react';
import CenterTitle from './CenterTitle';

class Subscribe extends Component{
    render(){
        return(
            <section className="subscribe-area" id="Subscribe">
                <div className="container">
                    <CenterTitle Title="Let's Partner" Subtitle="A business where everyone wins. Run your own Let’s Wash location or participate as a parking administrator/owner." />
                    <form className="mailchimp" method="post">
                        <div className="subcribe">
                            <input type="email" name="EMAIL" id="reg_email" className="form-control memail" placeholder="Enter your email..." />
                            <label>
                                <button className="btn sub_btn" type="submit">subscribe</button>
                            </label>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}
export default Subscribe;