import React, { Component, useEffect, useRef, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory, useParams } from "react-router-dom";
import WaterWave from "react-water-wave";
import { useSnackbar } from "notistack";
import { Alert, Badge, Button, FormCheck, FormControl } from "react-bootstrap";
import { isClosedHour, isClosedToday, isClosed, getLocation } from "../../utils/location.utils";
import { convertNumbThousand } from "../../utils/format.utils";
import { Checkbox } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, PaymentRequestButtonElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ formRef, setIsDisabled, setIsLoadingPayment }) => {

  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {


    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoadingPayment(true);

    const result = await stripe.confirmPayment({ elements, confirmParams: {
      return_url: `${process.env.REACT_APP_API_ENDPOINT}?accion=confirm_payment`,
    }});

    if (result.error) {
      enqueueSnackbar(result.error.message, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
    } else {
    }

    setIsLoadingPayment(false);
  };

  useEffect(() => {
    if (stripe) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [stripe])

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <PaymentElement />
    </form>
  );
}

export default CheckoutForm;
