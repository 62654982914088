import React, { Component } from "react";
import MainMenu from "../Menu/MainMenu";
import HomeBanner from "../Elements/HomeBanner";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";

class Home extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <MainMenu Logo="1.png" />
        <HomeBanner
          Details="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labo."
          Image="chart-img.jpg"
          BtnText="Get Started"
          Url="#"
        />
        <Works />
        <TopFeature />
        <Dashboard
          LeftTitle="Our Dashboard features"
          RightTitle="Our Dashboard features"
        />
        <DashboardTab />
        <CustomerSlider />
        <PriceList />
        <Subscribe />
        <Footer WidgetData={WidgetData} />
      </div>
    );
  }
}

export default Home;
