import React, { Component } from "react";
import TeamItem from "./TeamItem";

class Team extends Component {
  render() {
    var { LeftTitle, RightTitle } = this.props;
    return (
      <section className="appsy_team_area p_100" id="team">
        <div className="container">
          <div className="main_title">
            <h2>
              {LeftTitle} <samp>{RightTitle}</samp>
            </h2>
          </div>
          <div className="team_inner_area">
            <div className="team_slider_active row">
              <TeamItem Name="Rocky Ahammed" Image="team-1.jpg" Url="#" />
              <TeamItem Name="Manik Rony" Image="team-2.jpg" Url="#" />
              <TeamItem Name="Obaidul Hqu" Image="team-3.jpg" Url="#" />
              <TeamItem Name="Al Amin" Image="team-4.jpg" Url="#" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Team;
