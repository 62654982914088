import React, { Component, useEffect, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory, useParams } from "react-router-dom";
import WaterWave from "react-water-wave";
import { useSnackbar } from "notistack";
import { Alert, Badge, Button, FormCheck, FormControl } from "react-bootstrap";
import { isClosedHour, isClosedToday, isClosed, getLocation } from "../../utils/location.utils";
import { convertNumbThousand } from "../../utils/format.utils";
import { Checkbox } from "react-bootstrap";

const FormStep = () => {

  const { search } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ location, setLocation ] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : undefined)

  const [ idCarType, setIdCarType ] = useState(localStorage.getItem(`customer_id_car_type_${location?.code}`) ? localStorage.getItem(`customer_id_car_type_${location?.code}`) : location?.tipos_vehiculo[0]?.id_customer_car_type);
  const [ idWashType, setIdWashType ] = useState(localStorage.getItem(`customer_id_wash_type_${location?.code}`) ? localStorage.getItem(`customer_id_wash_type_${location?.code}`) : location?.tipos_lavado[0]?.id_customer_wash_type);
  const [ brand, setBrand ] = useState(localStorage.getItem('customer_brand') ? localStorage.getItem('customer_brand') : undefined);
  const [ model, setModel ] = useState(localStorage.getItem('customer_model') ? localStorage.getItem('customer_model') : undefined);
  const [ licensePlateNumber, setLicensePlateNumber ] = useState(localStorage.getItem('customer_license_plate_number') ? localStorage.getItem('customer_license_plate_number') : undefined);
  const [ reference, setReference ] = useState(localStorage.getItem(`customer_reference_${location?.code}`) ? localStorage.getItem(`customer_reference_${location?.code}`) : undefined);
  const [ checked, setChecked ] = useState(false);
  const [ price, setPrice ] = useState();

  const goToPayment = () => {
    if (!idCarType || !brand || !model || !licensePlateNumber || !checked) {
      enqueueSnackbar("Please fill every required field (*)", { variant: "warning", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
      return;
    }
    history.push("/payment");
    return;
  }

  useEffect(() => {
    if (!location) {
      history.push("/initial");
      return;
    }
    getLocation(location?.code).then((location) => {
      setLocation(location);
      localStorage.setItem('location', JSON.stringify(location));
    }).catch((err) => {
      enqueueSnackbar("An error ocurred. Please try again later.", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
      history.push("/initial");
    });

    localStorage.removeItem('customer_id_valet_car');
    localStorage.removeItem('customer_price');
    localStorage.removeItem('customer_client_secret');
  }, []);

  useEffect(() => {
    const _price = location?.tipos_vehiculo.filter(e => e.id_customer_car_type == idCarType)[0].price;
    setPrice(_price);
    localStorage.setItem("customer_price", _price);
  }, [idCarType]);

  useEffect(() => {
    idCarType ? localStorage.setItem(`customer_id_car_type_${location?.code}`, idCarType) : localStorage.removeItem(`customer_id_car_type_${location?.code}`);
    idWashType ? localStorage.setItem(`customer_id_wash_type_${location?.code}`, idWashType) : localStorage.removeItem(`customer_id_wash_type_${location?.code}`);
    brand ? localStorage.setItem('customer_brand', brand) : localStorage.removeItem('customer_brand');
    model ? localStorage.setItem('customer_model', model) : localStorage.removeItem('customer_model');
    licensePlateNumber ? localStorage.setItem('customer_license_plate_number', licensePlateNumber) : localStorage.removeItem('customer_license_plate_number');
    reference ? localStorage.setItem(`customer_reference_${location?.code}`, reference) : localStorage.removeItem(`customer_reference_${location?.code}`);
  }, [idCarType, idWashType, brand, model, licensePlateNumber, reference]);

  return (
    <div className="body_wrapper  md:h-[100vh] overflow-y-hidden">
      <MainMenuApp NoText={true} Logo="1.svg" />
      <section className="hero-area hero-ripples ripples">
        <div className="relative z-10 flex flex-col justify-between h-full">

          <img className="md:w-1/2 w-full object-cover h-[40vh] float-left" src={`${process.env.REACT_APP_IMAGES_ENDPOINT}${location?.imagen_bg}`} />
          
          <div className="md:w-1/2 fixed bottom-0 left-0 w-full bg-white !pt-6 rounded-t-2xl md:rounded-none shadow-[0_0_20px_rgba(0,0,0,0.5)] h-[62vh] text-neutral-900">
            <div className="flex flex-col justify-between h-full">
              <div className="space-y-4 overflow-y-auto !px-4 pb-6">
                <div>
                  <p className="text-base text-neutral-700">Now tell us more about your car and where have you parked.</p>
                </div>
                <div className="space-y-2">
                  <h2 className="text-base !font-semibold">Car type <span className="text-primary-6000">*</span></h2>
                  <div className="grid grid-cols-3 gap-4">
                    {location?.tipos_vehiculo.map((item, index) => (
                      <Button 
                        key={index}
                        variant="outline-info" 
                        className={`${item.id_customer_car_type == idCarType ? `!bg-primary-6000 !text-white !font-semibold` : `!text-primary-6000`} !border-primary-6000 !rounded-lg !py-2.5 !text-sm`} 
                        size="lg"
                        onClick={() => setIdCarType(item.id_customer_car_type)}
                      >
                        {item.name}
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <h2 className="text-base !font-semibold">Car brand <span className="text-primary-6000">*</span></h2>
                    <FormControl className="!text-sm !py-3 !h-auto" value={brand} onChange={(e) => setBrand(e.target.value)} name="brand" placeholder="Ford, Toyota, etc" />
                  </div>
                  <div className="space-y-2">
                    <h2 className="text-base !font-semibold">Car model <span className="text-primary-6000">*</span></h2>
                    <FormControl className="!text-sm !py-3 !h-auto" value={model} onChange={(e) => setModel(e.target.value)} name="model" placeholder="F150, Corolla, etc" />
                  </div>
                </div>
                <div className="space-y-2">
                  <h2 className="text-base !font-semibold">License Plate Number <span className="text-primary-6000">*</span></h2>
                  <FormControl className="!text-sm !py-3 !h-auto" value={licensePlateNumber} onChange={(e) => setLicensePlateNumber(e.target.value)} name="license_plate_number" placeholder="ABC 123" />
                </div>
                <div className="space-y-2">
                  <h2 className="text-base !font-semibold">Where is it parked?</h2>
                  <FormControl className="!text-sm !py-3 !h-auto" value={reference} onChange={(e) => setReference(e.target.value)} name="reference" placeholder="Lot 23, second floor" />
                </div>
                <div className="space-y-2">
                  <label className="flex space-x-2 items-center text-sm">
                    <FormCheck onChange={(e) => setChecked(e.target.checked)} />
                    <span>I have read and accept the <a className="!text-secondary-400 font-semibold" href="#">terms and conditions</a></span>
                  </label>
                </div>
              </div>
              <div className="py-2.5 space-y-2 border-t border-neutral-200 px-3">
                <div className="grid grid-cols-5 gap-3">
                  <Button 
                    variant="outline-info" 
                    className="col-span-1 !text-secondary-6000 !border-secondary-6000 !rounded-lg !py-2 !text-2xl" 
                    size="lg"
                    onClick={() => window.Tawk_API.maximize()}
                  >
                    <i className="las la-headset"></i>
                  </Button>
                  <Button 
                    className={`col-span-4 !text-sm !font-semibold uppercase !rounded-lg ${isClosedToday(location) || isClosedHour(location) ? `!bg-red-800 !border-red-800 !opacity-40` : `!bg-primary-6000`}`} 
                    size="lg"
                    disabled={isClosedToday(location)}
                    onClick={() => goToPayment()}
                  >
                    {isClosedToday(location) ?
                      "Closed today" 
                      :
                      isClosedHour(location) ?
                        "Closed now"
                        :
                        <>Go to payment &nbsp;<span className="!font-light">|</span>&nbsp; ${convertNumbThousand(price)}</>
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <iframe
            className="hidden md:block w-1/2 fixed top-0 left-1/2 h-[100vh] border-l border-neutral-200 border-solid "
            frameborder="0"
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?q=${location?.name}&key=AIzaSyA-kvvjMY47cmv3utgu1mJAMzc2MzS7Zbw`}
            allowfullscreen />
        </div>
      </section>
    </div>
  );
}

export default FormStep;
