import React, { Component, useEffect, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import { useHistory } from "react-router-dom";
import Partners from "../Elements/Partners";
import Administrators from "../Elements/Administrators";
import "../../assets/js/tawk.js";
 
const HomeThree = () => {

  const [ search, setSearch ] = useState();
  const history = useHistory();

  const handleOnClick = () => {
    if (search) {
      window.location.href = `/location/${search}`;
    }
  }

  useEffect(() => {
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/637f5e14b0d6371309d0e0aa/1gj6q6t27';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
  }, [])
  
  return (
    <div className="body_wrapper">
      <MainMenu Logo="1.svg" />
      <RippleBanner BtnText="Search" Url="" Image="home.jpg" setSearch={setSearch} OnClick={handleOnClick} />
      {/* <RippleBanner BtnText="Search" Url="#" Image="home.jpg" /> */}
      <Works AddClass="how-work2" />
      <Partners />
      <Administrators />
      <Testimonials
        BgImage="testi-bg-curve.png"
        LeftTitle="What our clients say"
        CenterTitle=""
        RightTitle=""
      />
      <Dashboard LeftTitle="Let's Wash" RightTitle="Traditional car wash" />
      <Contact />
      <Numbers Image="estacionamiento-frente.png" />
      <Footer WidgetData={WidgetData} />
    </div>
  );
}

export default HomeThree;
