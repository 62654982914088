import React, { Component, useState } from "react";
import WaterWave from "react-water-wave";
import Typer from "./Typer";
import QrReader from 'react-qr-scanner';
import { useTranslation } from "react-i18next";

const RippleBanner = ({ Details, BtnText, Url, Image, OnClick, setSearch }) => {

  const { t } = useTranslation();

  return (
    <>
      <section className="hero-area hero-ripples ripples min-h-[100vh] !mb-28 md:!mb-0">
        <WaterWave
          dropRadius={20}
          perturbance={0.025}
          strength={100}
          className="water_wave"
          style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          imageUrl={require("../../assets/image/baner/" + Image)}
        >
          {({ getRootProps }) => (
            <div className="w-full max-w-none p-[1.5rem] md:!px-20 relative !flex !flex-row !items-center !justify-center m-auto h-full relative">
              <div className="relative text-white z-10 !max-w-none w-full flex flex-col md:flex-row items-center h-full justify-between pt-28 md:pt-0">
                <div className="!space-y-12">
                  <h2 style={{ textAlign: "center" }} className="md:!text-left font-bold text-2.5xl md:text-6xl max-w-3xl leading-[2.5rem] md:leading-[4rem]">
                    <span id="">{t("hero.title_1")}<span className="text-secondary-200">{t("hero.title_2")}</span></span>
                  </h2>
                  <span className="hidden md:block">{t("hero.subtitle")}</span>
                  <div className="relative max-w-none md:max-w-sm">
                    <input 
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={(e) => { if (e.key === 'Enter') OnClick() }}
                      placeholder={t("hero.search_by")}
                      className="w-full pl-4 pr-5 rounded-full py-2.5 bg-white text-black border-2 border-solid -mr-12 outline-0"
                    />
                    <span
                      className="btn wow fadeInLeft !text-base !px-3 !py-2 z-10 relative !bg-secondary-400 text-white !rounded-full -mt-0.5"
                      data-wow-delay="300ms"
                      onClick={() => OnClick()}
                    >
                      <i className="las la-search"></i>
                    </span>
                    {/* <a
                      href={Url}
                      className="btn wow fadeInLeft !text-base !px-3 !py-2 z-10 relative !bg-secondary-400 text-white !rounded-full -mt-0.5 mr-10"
                      data-wow-delay="300ms"
                      onClick={() => this.setState({ ...this.state, qrOpen: true })}
                    >
                      <i className="las la-qrcode"></i>
                    </a> */}
                  </div>
                  {/* <span className="block md:hidden text-center">Forget about wasting time driving to the car wash and waiting for your car to be washed. We go to your car.</span> */}
                </div>
                <div className="flex items-center justify-between w-[80%] md:w-[40%] flex-grow relative mt-16 md:mt-10">
                  <video poster="/images/screenshot.jpg" autoPlay={true} loop={true} muted={true} playsInline={true} className="absolute w-[62%] top-[2%] left-[19%] rounded-xl">
                    <source src="/video/video.mov" type="video/mp4" />
                    <source src="/video/video.webm" type="video/webm" />
                  </video>
                  <img className="w-[70%] m-auto z-10 relative" src={require("../../assets/image/iphone.png")} />
                </div>
              </div>
            </div>
          )}
        </WaterWave>
      </section>
    </>
  );
}
export default RippleBanner;
