import React, {Component} from 'react';
import DashItem from './DashItem';

import Background from '../../assets/image/baner/vs.jpg';

var Image = {
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  };

class Dashboard extends Component{ 
    render(){
        var {LeftTitle, RightTitle} = this.props;
        return(
            <section id='dashboard' className="powerful-features" style={ Image }>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 powerful-items power-item-l">
                            <h2 className="po-title !font-bold">{LeftTitle}</h2>
                            <DashItem Class="wow fadeInUp" delay="200ms"
                                Icon={require("../../assets/image/icon/quick_easy.svg").default}
                                Title="Quick and easy"
                                Details="Submit your request in less than 1 minute and come back to your clean car."
                            />
                            <DashItem Class="wow fadeInUp" delay="600ms"
                                Icon={require("../../assets/image/icon/go_to_car.svg").default}
                                Title="We go to your car"
                                Details="There is no need to add a destination to your route. We wash your car while it is parked."
                            />
                            <DashItem Class="wow fadeInUp" delay="900ms"
                                Icon={require("../../assets/image/icon/eco_friendly.svg").default}
                                Title="Eco-friendly wash ™"
                                Details="We use 99% less water, and all cleaning supplies are eco-friendly."
                            />
                        </div>
                        <div className="col-md-6 powerful-items power-item-r">
                            <div className="vs-icon">vs</div>
                            <h2 className="po-title !font-bold">{RightTitle}</h2>
                            <DashItem Class="wow fadeInUp" delay="400ms"
                                Icon={require("../../assets/image/icon/long_wait.svg").default}
                                Title="Long waits"
                                Details="You have to wait while your car is washed… But first line up and wait for your turn!"
                            />
                            <DashItem Class="wow fadeInUp" delay="800ms"
                                Icon={require("../../assets/image/icon/drive_to_carwash.svg").default}
                                Title="Drive to the car wash"
                                Details="Add a destination to your route, making it an even more time-consuming process."
                            />
                            <DashItem Class="wow fadeInUp" delay="1s"
                                Icon={require("../../assets/image/icon/environmental_damage.svg").default}
                                Title="Environmental damaging"
                                Details="Wastes large amounts of water and uses environmentally harmful cleaning supplies."
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Dashboard;