export const getLocation = async (search) => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT}?accion=get_location&search=${search}`, {
  }).then((response) => {
    return response.json();
  }).then((json) => {
    return json;
  })
}
export const getLocationById = async (id_customer) => {
  return await fetch(`${process.env.REACT_APP_API_ENDPOINT}?accion=get_location&id_customer=${id_customer}`, {
  }).then((response) => {
    return response.json();
  }).then((json) => {
    return json;
  })
}

export const isClosedHour = (location) => {
    if (location) {
      const date = new Date().getUTCFullYear() + "-" + String(new Date().getUTCMonth() + 1).padStart(2, '0') + "-" + String(new Date().getUTCDate()).padStart(2, '0');
      const time = String(new Date().getUTCHours()).padStart(2, '0') + ":" + String(new Date().getUTCMinutes()).padStart(2, '0');
      
      const open_time = String(location.open_time).length < 5 ? '0' + location.open_time : location.open_time;
      const close_time = String(location.close_time).length < 5 ? '0' + location.close_time : location.close_time;

      const now = new Date(`${date} ${time}`);
      const openHour = new Date(`${date} ${open_time}`);
      let closeHour = new Date(`${date} ${close_time}`);
      if (closeHour.getTime() < openHour.getTime()) {
        closeHour = new Date(closeHour.getTime() + 1000 * 24 * 3600);
      }

      if (now.getTime() >= openHour.getTime() && now.getTime() < closeHour.getTime()) {
        return false;
      }
    }
    return true;
}

export const isClosedToday = (location) => {
  if (location) {
    const weekDay = new Date().getUTCDay();
    const operationWeekDays = location?.week_days.split(',');
    if (!operationWeekDays.includes(String(weekDay))) {
      return true;
    }
  }
  return false;
}

export const isClosed = (location) => {
    return isClosedToday(location) || isClosedHour(location)
}
