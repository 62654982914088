import React, {Component} from 'react';
import CenterTitle from './CenterTitle';
import WorkItem from './WorkItem';
import WaterWave from "react-water-wave";



class Numbers extends Component{
    render(){
        var { Image } = this.props;
        return(
            <section className={"relative"}>
                <WaterWave
                    dropRadius={20}
                    perturbance={0.025}
                    strength={100}
                    className="water_wave"
                    style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
                    imageUrl={require("../../assets/image/baner/" + Image)}
                >
                    {({ getRootProps }) => (
                        <div className="container !pt-8 !py-14 md:!py-16 md:!pt-20 relative z-10">
                            <div className="row !space-y-16 md:!space-y-0">
                                <WorkItem Class="wow fadeIn" delay="0.5s"
                                    Icon={require("../../assets/image/icon/cars.svg").default}
                                    Title="+1000 cars washed"
                                    titleClass="!text-lg !text-white opacity-100"
                                    iconClass="!mb-0 !h-10 !w-10 no-hover !bg-transparent"
                                    bootstrapCol={3}
                                    imgClass="brightness-[200] contrast-200 saturate-200 grayscale"
                                />
                                <WorkItem Class="wow fadeIn" delay="1s"
                                    Icon={require("../../assets/image/icon/locations.svg").default}
                                    Title="+15 locations"
                                    titleClass="!text-lg !text-white opacity-100"
                                    iconClass="!mb-0 !h-10 !w-10 no-hover !bg-transparent"
                                    bootstrapCol={3}
                                    imgClass="brightness-[200] contrast-200 saturate-200 grayscale"
                                />
                                <WorkItem Class="wow fadeIn" delay="1.3s"
                                    Icon={require("../../assets/image/icon/satisfied.svg").default}
                                    Title="99% satisfied customers"
                                    titleClass="!text-lg !text-white opacity-100"
                                    iconClass="!mb-0 !h-10 !w-10 no-hover !bg-transparent"
                                    bootstrapCol={3}
                                    imgClass="brightness-[200] contrast-200 saturate-200 grayscale"
                                />
                                <WorkItem Class="wow fadeIn" delay="1.3s"
                                    Icon={require("../../assets/image/icon/income.svg").default}
                                    Title="+30% income increase"
                                    titleClass="!text-lg !text-white opacity-100"
                                    iconClass="!mb-0 !h-10 !w-10 no-hover !bg-transparent"
                                    bootstrapCol={3}
                                    imgClass="brightness-[200] contrast-200 saturate-200 grayscale"
                                />
                            </div>
                        </div>
                    )}
                </WaterWave>
            </section>
        )
    }
}
export default Numbers;