import React, { Component } from "react";

class HomeBlogLeft extends Component {
  render() {
    var {
      Image,
      Date,
      Title,
      Details,
      BtnText,
      Url,
      DateUrl,
      TitleUrl
    } = this.props;
    return (
      <div className="l_news_item">
        <div className="l_news_img">
          <img
            className="img-fluid"
            src={require("../../assets/image/blog/" + Image)}
            alt=""
          />
          <a href={DateUrl}>{Date}</a>
        </div>
        <div className="l_news_text">
          <a href={TitleUrl}>
            <h4>{Title}</h4>
          </a>
          <p>{Details}</p>
          <a className="more_btn" href={Url}>
            {BtnText}
          </a>
        </div>
      </div>
    );
  }
}
export default HomeBlogLeft;
