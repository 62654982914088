import React, { Component } from "react";
import { Link } from "react-scroll";

class MenuTwo extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll");
  }

  render() {
    var { Logo } = this.props;
    return (
      <header
        className={
          this.state.scrolled
            ? "main_menu menu_two scrollled"
            : "main_menu menu_two"
        }
      >
        <nav className="navbar navbar-expand-lg navbar-light bg-light header-area">
          <a className="navbar-brand" href=".#">
            <img src={require("../../assets/image/logo/" + Logo)} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="nav navbar-nav menu_nav ml-auto menu">
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  Testimonial
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="team"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="download"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  Download
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  activeclass="active"
                  to="news"
                  spy={true}
                  smooth={true}
                  offset={-35}
                  duration={500}
                >
                  News
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li className="inline-popups">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="ion-ios-search"></i>
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
export default MenuTwo;
