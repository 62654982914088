import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detectBrowserLanguage from "detect-browser-language";

const resources = {
  en: {
    translation: {
        navbar: {
            home: "Home",
            how_it_works: "How it works",
            why_us: "Why Let's Wash",
            lets_partner: "Let's Partner",
        },
        hero: {
            title_1: "Get a car wash while parked. ",
            title_2: "Scan the QR and we go to your car.",
            subtitle: "Forget about wasting time driving to the car wash and waiting for your car to be washed. We go to your car.",
            search_by: "Search by code or location"
        },
        works: {
            title: "How It Works?",
            subtitle: "The best way to get a car wash without waiting. Submit your request in less than 1 minute!",
            item0: {
                title: "Scan the QR",
                subtitle: "Access the Let’s Wash app easily and quickly with any phone, tablet or computer"
            },
            item1: {
                title: "Enter your car details",
                subtitle: "Provide your car brand & model, and your plate number so we can find it"
            },
            item2: {
                title: "Pick up your clean car",
                subtitle: "Continue your day without wasting a single minute in your car wash"
            }
        },
        partner: {
            title: "Open your own Let’s Wash location",
            subtitle: "Start operating with a minimum investment. We provide everything you need to get you started!",
            item0: {
                title: "We provide the know-how",
                subtitle: "We teach you how to wash a parked car and use Let's Wash technology to optimize operations."
            },
            item1: {
                title: "We provide all the cleaning tools",
                subtitle: "We make sure you have everything you need so you can focus on providing the best car washes.",
            },
            item2: {
                title: "You increase your income",
                subtitle: "Create a new source of income by managing a Let's Wash location."
            },
            button: "Start today!"
        },
        administrator: {
            title: "Participate as a parking administrator/owner",
            subtitle: "Own or adminstrate a parking lot, hotel, shopping mall, or residence building and want to increase your earnings? Give us a call!",
            item0: {
                title: "Contact us",
                subtitle: "Fill out the form with your email and a representative will guide you step by step."
            },
            item1: {
                title: "We find you the best washer",
                subtitle: "We assign one of our qualified washing partners to your parking lot.",
            },
            item2: {
                title: "You increase your income",
                subtitle: "On average, parking lots increase their income between 20% and 50% with Let’s Wash."
            },
            button: "Contact Us"
        }
    }
  },
  es: {
    translation: {
        navbar: {
            home: "Inicio",
            how_it_works: "Cómo funciona",
            why_us: "Por qué elegirnos",
            lets_partner: "Asociarse",
        },
        hero: {
            title_1: "Lavamos su auto a donde esté. ",
            title_2: "Escanea el QR y vamos a su auto.",
            subtitle: "Olvídate de perder tiempo conduciendo hacia el lavadero de autos. Nostros vamos a tu auto.",
            search_by: "Búsqueda por código o nombre"
        }
    },
  },
};

const browserLanguage = detectBrowserLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || browserLanguage || "en",
  fallbackLng: {
    default: ["en"],
  },
  interpolation: {
    escapeValue: false,
  },
});