import React, { Component } from "react";
import OfferLeft from "./OfferLeft";
import OfferRight from "./OfferRight";

class SwipeOffers extends Component {
  render() {
    var { Title1, Title2, Title3 } = this.props;
    return (
      <section className="feature_area p_100" id="features">
        <div className="container">
          <div className="main_title">
            <h2>
              <samp>{Title1}</samp> {Title2}
              <br />
              {Title3}
            </h2>
          </div>
          <OfferLeft
            LeftImg="feature-2.png"
            CenterImg="feature-1.png"
            RightImg="feature-3.png"
            Title="Build the App that everyone love."
            Details="Ever wondered how some graphic designers always manage to produce beautiful looking designs for their brochures, website designs, logo designs? Talent…yes, it helps but there are a hand- ful of more important things you can do that will have even complete beginners producing award winning design."
            BtnText="Learn More"
            Url="#"
          />
          <OfferRight
            MobileImg="facebook.png"
            LoveImg="chat-1.png"
            ChatImg="chat-2.png"
            InternetImg="chat-3.png"
            Title="Unlimited Major Functionalites"
            Details="Ever wondered how some graphic designers always manage to produce beautiful looking designs for their brochures, website designs, logo designs? Talent…yes, it helps but there are a hand- ful of more important things you can do that will have even complete beginners producing award winning design."
            BtnText="Learn More"
            Url="#"
          />
        </div>
      </section>
    );
  }
}
export default SwipeOffers;
