import React, { Component, useEffect, useState } from "react";
import MainMenu from "../Menu/MainMenu";
import Works from "../Elements/Works";
import TopFeature from "../Elements/TopFeature";
import Dashboard from "../Elements/Dashboard";
import DashboardTab from "../Elements/DashboardTab";
import CustomerSlider from "../Elements/CutomerSlider";
import PriceList from "../Elements/PriceList";
import Subscribe from "../Elements/Subscribe";
import Footer from "../Elements/Footer";
import WidgetData from "../Elements/widgets/WidgetData";
import RippleBanner from "../Elements/RippleBanner";
import Numbers from "../Elements/Numbers";
import Testimonials from "../Elements/Testimonials";
import Contact from "../Elements/Contact";
import MainMenuApp from "../Menu/MainMenuApp";
import { useHistory, useParams } from "react-router-dom";
import WaterWave from "react-water-wave";
import { useSnackbar } from "notistack";
import { Alert, Badge, Button } from "react-bootstrap";
import { isClosedHour, isClosedToday, isClosed, getLocation } from "../../utils/location.utils";
import TawkTo from "../Elements/TawkTo";

const LocationStep = () => {

  const { search } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ location, setLocation ] = useState(localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : undefined)

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const operationWeekDays = location ? location?.week_days.split(',') : [];

  useEffect(() => {
    setIsLoading(true);
    getLocation(search).then((location) => {
      setLocation(location);
      localStorage.setItem('location', JSON.stringify(location));
    }).catch((err) => {
      if (String(err).includes("JSON")) {
        enqueueSnackbar("Location not found. Check the code and try again.", { anchorOrigin: { horizontal: "center", vertical: "bottom" } })
        history.push("/initial");
        return;
      } 
      enqueueSnackbar("An error ocurred. Please try again later.", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
      history.push("/initial");
    }).finally(() => {
      setIsLoading(false);
    });

    localStorage.removeItem('customer_id_valet_car');
    localStorage.removeItem('customer_price');
    localStorage.removeItem('customer_client_secret');
  }, [search]);

  return (
    <div className="body_wrapper h-[100vh] overflow-y-hidden">
      <MainMenuApp NoText={true} Logo="1.svg" />
      <section className="hero-area hero-ripples ripples">
        <WaterWave
          dropRadius={20}
          perturbance={0.025}
          strength={100}
          className="water_wave"
          style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          imageUrl={require("../../assets/image/baner/home.jpg")}
        >
          {() => (
            <>
              {isLoading ?
                <div className="flex items-center justify-center w-full h-full text-white z-10 relative">
                  <i className="las la-spinner la-spin text-6xl"></i>
                </div>
                :
                <div className="md:w-1/2 relative z-10 flex flex-col justify-between h-full overflow-y-hidden">

                  <img className="w-full object-cover h-[40vh] float-left" src={`${process.env.REACT_APP_IMAGES_ENDPOINT}${location?.imagen_bg}`} />
                  
                  <div className="md:w-1/2 fixed bottom-0 left-0 w-full bg-white !pt-6 rounded-t-2xl md:rounded-none shadow-[0_0_20px_rgba(0,0,0,0.5)] h-[62vh] text-neutral-900">
                    <div className="flex flex-col justify-between h-full">
                      <div className="flex flex-col justify-between h-full !px-4">
                        <div className="space-y-4">
                          <div className="space-y-1.5">
                            <div className="flex justify-between">
                              <h2 className="text-xl !font-semibold w-full">{location?.name}</h2>
                              <Badge className="!bg-secondary-400 !font-normal !px-2 py-2 right-5 top-10" variant="success">Code: <b className="font-bold">{location?.code}</b></Badge>
                            </div>
                            <span className="text-base text-neutral-600 flex items-center space-x-1">
                              <i className="las la-map-marker text-lg"></i> <span>{location?.address}</span>
                            </span>
                          </div>
                          <div className="rounded-lg bg-primary-50 px-4 py-2.5 text-primary-6000 text-sm font-semibold grid grid-cols-2">
                            <div className="flex items-center space-x-2">
                              <i className="las la-calendar text-lg"></i> <span>{location?.operation_hours}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <i className="las la-clock text-lg"></i> <span>{location?.wait_time} mins wait</span>
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="text-base text-neutral-700">Is your car parked? You are good to go! Just order a car wash and we'll go to your car.</p>
                          </div>
                        </div>
                        <div className="space-y-4">
                          {isClosedToday(location) &&
                            <Alert 
                              variant="danger" 
                              className="text-sm border-0"
                            >
                              This location is closed today. Opens {weekDays.filter((_value, index) => {return operationWeekDays.includes(String(index))}).join(", ")} from {location?.operation_hours}
                            </Alert>
                          }
                          {isClosedHour(location) && !isClosedToday(location) &&
                            <Alert 
                              variant="danger" 
                              className="text-sm border-0"
                            >
                              This location is now closed. Operation hours from {location?.operation_hours}
                            </Alert>
                          }
                          {!isClosed(location) && location?.alert_message.trim() && 
                            <Alert 
                              variant="info" 
                              className="!bg-secondary-50 !text-secondary-6000 text-sm border-0"
                            >
                              {location?.alert_message.trim()}
                            </Alert>
                          }
                        </div>
                      </div>
                      <div className="py-2.5 space-y-2 border-t border-neutral-200 px-3">
                        <div className="grid grid-cols-5 gap-3">
                          <TawkTo />
                          <Button 
                            className={`col-span-4 !text-sm !font-semibold uppercase !rounded-lg ${isClosedToday(location) || isClosedHour(location) ? `!bg-red-800 !border-red-800 !opacity-40` : `!bg-primary-6000`}`} 
                            size="lg"
                            disabled={isClosedToday(location)}
                            onClick={() => history.push("/form")}
                          >
                            {isClosedToday(location) ?
                              "Closed today" 
                              :
                              isClosedHour(location) ?
                                "Closed now"
                                :
                                "Wash my car"
                            }
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <iframe
                    className="hidden md:block w-1/2 fixed top-0 left-1/2 h-[100vh] border-l border-neutral-200 border-solid "
                    frameborder="0"
                    referrerpolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?q=${location?.name}&key=AIzaSyA-kvvjMY47cmv3utgu1mJAMzc2MzS7Zbw`}
                    allowfullscreen />
                </div>
              }
            </>
          )}
        </WaterWave>
      </section>
    </div>
  );
}

export default LocationStep;
