import React, { Component } from "react";

class HomeBlogList extends Component {
  render() {
    var { Image, Title, TitleUrl, Date, DateUrl } = this.props;
    return (
      <div className="media">
        <div className="d-flex">
          <img
            className="img-fluid"
            src={require("../../assets/image/blog/" + Image)}
            alt=""
          />
        </div>
        <div className="media-body">
          <div className="news_list_item">
            <a href={TitleUrl}>
              <h4>{Title}</h4>
            </a>
            <a className="date" href={DateUrl}>
              {Date}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeBlogList;
