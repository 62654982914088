import React, { Component } from "react";

class OfferLeft extends Component {
  render() {
    var {
      LeftImg,
      CenterImg,
      RightImg,
      Title,
      Details,
      BtnText,
      Url
    } = this.props;
    return (
      <div className="feature_inner row">
        <div className="col-lg-7">
          <div className="feature_img">
            <img
              className="img-fluid"
              src={require("../../assets/image/" + CenterImg)}
              alt=""
            />
            <img
              className="img-fluid"
              data-parallax='{"x": 0, "y": 200}'
              src={require("../../assets/image/" + LeftImg)}
              alt=""
            />
            <img
              className="img-fluid"
              data-parallax='{"x": 0, "y": -150}'
              src={require("../../assets/image/" + RightImg)}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-5">
          <div className="feature_text">
            <div className="icon">
              <i className="fa fa-pencil"></i>
            </div>
            <h4>{Title}</h4>
            <p>{Details}</p>
            <a className="store_btn" href={Url}>
              {BtnText}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default OfferLeft;
