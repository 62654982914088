import React, { Component } from "react";
import "./App.css";
import "./index.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Home from "./components/Pages/Home";
import HomeTwo from "./components/Pages/HomeTwo";
import HomeThree from "./components/Pages/HomeThree";
import HomeFour from "./components/Pages/HomeFour";
import HomeFive from "./components/Pages/HomeFive";
import InitialStep from "./components/Pages/InitialStep";
import Location from "./components/Pages/LocationStep";
import FormStep from "./components/Pages/FormStep";
import PaymentStep from "./components/Pages/PaymentStep";
import WaitStep from "./components/Pages/WaitStep";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <SnackbarProvider>
              <Route exact={true} path={"/"} component={HomeThree} />
              <Route path={"/HomeTwo"} component={HomeTwo} />
              <Route path={"/HomeThree"} component={Home} />
              <Route path={"/HomeFour"} component={HomeFour} />
              <Route path={"/HomeFive"} component={HomeFive} />
              <Route path={"/initial"} component={InitialStep} />
              <Route path={"/location/:search"} component={Location} />
              <Route path={"/form"} component={FormStep} />
              <Route path={"/payment"} component={PaymentStep} />
              <Route path={"/wait/:idValetCar"} component={WaitStep} />
            </SnackbarProvider>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
