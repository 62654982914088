import React, {Component} from 'react';

class ContactWD extends Component{
    render(){
        let WidgetData = this.props.WidgetData;
        var {Class, delay} = this.props;
        return(
            <div className={"f-widget legal_widget col-lg-4 col-md-4 col-sm-4 " + Class} data-wow-delay={delay}>
                <h4 className="widget_title text-left hidden md:block">{this.props.Title}</h4>
                <div className="widget_inner m0 w-full text-left hidden md:block">
                    <ul className='w-full'>
                        {
                            WidgetData.Contact.map(item =>{
                                return(
                                    <li key={item.id}><a href={item.url}>{item.name}</a></li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="widget_inner m0 w-full text-center md:hidden">
                    <ul className='w-full'>
                        {
                            WidgetData.Contact.map(item =>{
                                return(
                                    <li key={item.id}><a href={item.url}>{item.name}</a></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
export default ContactWD;