import React, { Component } from "react";
import FeatureBoxList from "./FeatureBoxList";

class FeatureBoxItem extends Component {
  render() {
    var { Title, Details, Icon } = this.props;
    return (
      <div className="col-lg-4 col-md-6">
        <div className="overview_item">
          <div className="icon">
            <i className={Icon}></i>
          </div>
          <h4>{Title}</h4>
          <p>{Details}</p>
          <ul className="list">
            <FeatureBoxList Text="Eiusmod tempor incididunt" Url="#" />
            <FeatureBoxList Text="Adipisicing elit, sed do…" Url="#" />
            <FeatureBoxList Text="ios App Development" Url="#" />
          </ul>
        </div>
      </div>
    );
  }
}
export default FeatureBoxItem;
