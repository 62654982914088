import React, { Component } from "react";
import TeamSocial from "./TeamSocial";

class TeamItem extends Component {
  render() {
    var { Name, Image, Url } = this.props;
    return (
      <div className="col-lg-3 col-sm-6">
        <div className="item">
          <img src={require("../../assets/image/team/" + Image)} alt="" />
          <div className="hover_team">
            <div className="hover_team_inner">
              <a href={Url}>
                <h4>
                  <span>{Name}</span>
                </h4>
              </a>
              <ul>
                <TeamSocial Url="#" Icon="fa fa-facebook" />
                <TeamSocial Url="#" Icon="fa fa-twitter" />
                <TeamSocial Url="#" Icon="fa fa-dribbble" />
                <TeamSocial Url="#" Icon="fa fa-linkedin" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TeamItem;
