const WidgetData = {
    PageLink: [
        {
            id: 1,
            name: 'About',
            url: '#',
        },
        {
            id: 2,
            name: 'Blog',
            url: '#',
        },
        {
            id: 3,
            name: 'Talent Check',
            url: '#',
        },
        {
            id: 4,
            name: 'Career',
            url: '#',
        },
        {
            id: 5,
            name: 'Contact',
            url: '#',
        },
    ],
    Work: [
        {
            id: 1,
            name: 'Our Clients',
            url: '#'
        },
        {
            id: 2,
            name: 'Skills',
            url: '#'
        },
        {
            id: 3,
            name: 'Consultants',
            url: '#'
        },
    ],
    Legal: [
        {
            id: 1,
            name: 'Privacy',
            url: '#'
        },
        {
            id: 2,
            name: 'Terms',
            url: '#'
        },
        {
            id: 3,
            name: 'Advertise',
            url: '#'
        },
    ],
    Contact: [
        {
            id: 1,
            name: 'Miami, Florida, US / Buenos Aires, Arg',
            url: '#'
        },
        {
            id: 2,
            name: 'hello@letswash.app',
            url: 'mailto:hello@letswash.app'
        },
    ],
    Social: [
        {
            id: 1,
            url: 'https://www.linkedin.com/company/lets-wash/',
            icon: 'fa fa-linkedin',
        },
        {
            id: 2,
            url: 'https://instagram.com/letswash.app?igshid=YzdkMWQ2MWU=',
            icon: 'fa fa-instagram',
        },
    ],
    PageList: [
        {
            id: 1,
            name: 'Privacy Policy',
            url: '#',
        },
        {
            id: 2,
            name: 'About',
            url: '#',
        },
        {
            id: 3,
            name: 'FAQ',
            url: '#',
        },
        {
            id: 4,
            name: 'Contact Support',
            url: '#',
        },
    ],
}
export default WidgetData;