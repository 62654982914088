import React, {Component} from 'react';
import CenterTitle from './CenterTitle';
import WorkItem from './WorkItem';
import { useTranslation } from "react-i18next";



const Works = ({AddClass}) => {

  const { t } = useTranslation();

    return(
        <section id="works" className={"how-work-area !py-24 " + AddClass}>
            <div className="container">
                <CenterTitle Title={t("works.title")} Subtitle={t("works.subtitle")} />
                <div className="row">
                    <WorkItem Class="wow fadeIn" delay="0.5s"
                        Icon={require("../../assets/image/icon/qr.svg").default}
                        Title={t("works.item0.title")}
                        Details={t("works.item0.subtitle")}
                        imgClass="group-hover:brightness-[200] group-hover:contrast-200 group-hover:saturate-200 group-hover:grayscale"
                    />
                    <WorkItem Class="wow fadeIn" delay="1s"
                        Icon={require("../../assets/image/icon/enter_details.svg").default}
                        Title={t("works.item1.title")}
                        Details={t("works.item1.subtitle")}
                        imgClass="group-hover:brightness-[200] group-hover:contrast-200 group-hover:saturate-200 group-hover:grayscale"
                    />
                    <WorkItem Class="wow fadeIn" delay="1.3s"
                        Icon={require("../../assets/image/icon/clean_car.svg").default}
                        Title={t("works.item2.title")}
                        Details={t("works.item2.subtitle")}
                        imgClass="group-hover:brightness-[200] group-hover:contrast-200 group-hover:saturate-200 group-hover:grayscale"
                    />
                </div>
            </div>
        </section>
    )
}
export default Works;